import LAYOUT from '@/layout/layout';
import moduleLayout from '@/views/Settings/Settings.vue';
import scenarioLayout from '../../views/Dialog/Dialog.vue';

export default [
  {
    path: '/settings',
    component: LAYOUT.base,
    redirect: '/settings/bots',
    children: [
      {
        path: 'engines',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsEngines',
            component: () => import(/* webpackChunkName: 'settings-engines' */ '@/views/Settings/tabs/EnginesTab.vue'),
          },
        ],
      },
      {
        path: 'bots',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsBots',
            component: () => import(/* webpackChunkName: 'settings-bots' */ '@/views/Settings/tabs/BotsTab.vue'),
          },
        ],
      },
      {
        path: 'bot-groups',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BotGroups',
            component: () => import(/* webpackChunkName: 'settings-bot' */ '@/views/Settings/tabs/BotGroupsTab.vue'),
          },
        ],
      },
      {
        path: "bot-settings/:id",
        name: "bot_settings",
        component: () => import(/* webpackChunkName: '' */ "@/views/Settings/BotSettings"),
      },
      {
        path: 'hooks',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsHooks',
            component: () => import(/* webpackChunkName: 'settings-hooks' */ '@/views/Settings/tabs/BotTab_hooks.vue'),
          },
        ],
      },
      {
        path: 'languages',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsLanguages',
            component: () => import(/* webpackChunkName: 'settings-languages' */ '@/views/Settings/tabs/BotTab_languages.vue'),
          },
        ],
      },
      {
        path: 'users',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsUsers',
            component: () => import(/* webpackChunkName: 'settings-users' */ '@/views/Settings/tabs/UsersTab.vue'),
          },
        ],
      },
      {
        path: 'roles',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsRoles',
            component: () => import(/* webpackChunkName: 'settings-roles' */ '@/views/Settings/tabs/RolesTab.vue'),
          },
        ],
      },
    ],
  },
];
